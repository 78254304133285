.extrasex1-img {
    position: relative;
    width: fit-content;
    max-width: 100%;
    margin: auto;
}
 .extrasex1-img::before {
    border-bottom: 0px solid #ff3f6c;
    border-right: 0px solid #fff;
    content: "";
    right: 0;
    top: 0;
    position: absolute;
    transition: 0.4s;
    z-index: 1;
}
.extrasex1-img:hover::before {
    transition: 0.4s;
    border-bottom: 50px solid #ff3f6c;
    border-right: 50px solid #fff;
}

.custom-col {
    width: 20% !important; 
  }


@media (max-width: 992px) {
    .custom-col {
      width: 50% !important;  
    }
  }
  
  @media (max-width: 576px) {
    .custom-col {
      width: 100% !important; 
    }
  }