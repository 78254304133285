.section-2-header {
}
.section2-card {
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  align-items: center; /* Horizontally center the content */
  text-align: start;
  color: var(--default-text-color-gentlemenz);
  text-transform: uppercase;
  height: 100%; /* Ensures content stretches vertically to allow centering */
}
.title {
  font-size: 2.7rem;
  font-weight: 800;
}
.action-btn {
  border-radius: 50px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
}
