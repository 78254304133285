.address-lines {
  font-size: 14px;
}

.cart-action-btn {
  font-size: 14px !important;
}
.book-address-action-btn {
  text-decoration: underline;
  background-color: inherit;
  font-weight: 500;
  border: 1px solid #282c3f;
  border-radius: 4px;
  color: #424553;
  font-size: 12px;
  font-weight: 700;
  margin: 7px 0 6px;
  padding: 8px 16px 7px;
}
.book-address-section {
  width: 450px;
  border: 1px solid #eaeaec;
  padding: 8px;
  box-shadow: 0 0 4px #282c3f33;
}
.book-address-tag {
  border: 1px solid #03a685;
  font-size: 10px;
  color: #03a685;
  border-radius: 8px;
  padding: 1px 7px;
  font-weight: 600;
}
.phone {
  font-size: 14px;
}
@media (max-width: 768px) {
  .book-address-section {
    width: 300px;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .book-address-section {
    width: 250px;
    padding: 10px;
  }
}

@media (min-width: 1024px) {
  .book-address-section {
    width: 450px;
    padding: 10px;
  }
}
