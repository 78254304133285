.clearFilterButton {
    border: none;
    /* padding: 0.5em 1em; */
    padding: 3px 10px;
    /* border-radius: 8px; */
    font-weight: 700;
    background-color: #ff3f6c;
    color: #fff;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    font-size: 14px;
  }
  .clearFilterButton:hover,
  .clearFilterButton:focus,
  .clearFilterButton:active {
    background-color: #ff3f6c;
  }
  
  .sing_filter {
    padding: 20px 0px;
    /* border-bottom: 2px solid #e9e9e9; */
  }
  
  .sing_filter h6 {
    font-weight: 800;
    /* text-transform: uppercase; */
    font-size: 14px;
    margin: 0 0 18px;
    clear: both;
    color: #000 !important;
    display: block;
  }
  
  .sing_filter ul {
    margin: 0;
    padding: 0;
    list-style: none;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    height: 280px;
    overflow-y: auto;
  }
  
  .sing_filter ul::-webkit-scrollbar-track {
    background-color: #e9e9e9;
  }
  
  .sing_filter ul::-webkit-scrollbar {
    width: 3px;
    margin-left: 10px;
    background-color: #e9e9e9;
  }
  
  .sing_filter ul::-webkit-scrollbar-thumb {
    background-color: #000;
  }
  .sing_filter.has_more_btn ul {
    height: 600px;
    overflow-y: auto;
  }
  
  .sing_filter.has_more_btn ul.show {
    max-height: max-content;
    overflow-y: auto;
  }
  
  .sing_filter ul li {
    display: flex;
    gap: 6px;
    align-items: center;
    /* border-bottom: 1px solid #eee; */
    padding-bottom: 10px;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--dark_grey) !important;
    font-size: 14px;
    margin-right: 8px;
    padding-top: 5px;
  }
  
  .sing_filter ul li span {
    color: #94969f !important;
    font-size: 11px;
  }
  
  .sing_filter .sing_filter_more_btn {
    color: var(--pink);
    font-size: 16px;
    padding-left: 28px;
  }
  
  .section_p_f_fil_cont .s_p_f_f_c_right {
    padding: 0;
    padding-top: 45px;
  }
  
  /* radio */
  
  
  /* Hide the default radio button */
  .shopradio {
    display: none;
  }
  
  /* Custom radio button style */
  .shopradio + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-weight: 400;
  
    /* font-size: 16px; */
    color: #000 !important;
  }
  
  .shopradio + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 1px solid #ff3f6c;
    border-radius: 50%;
    background-color: white;
    transition: all 0.3s ease;
  }
  
  .shopradio:checked + label::before {
    background-color: white; 
    border-color: #ff3f6c; 
  }
  
  .shopradio:checked + label::after {
    content: "";
    position: absolute;
    left: 3.5px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff3f6c; 
  }
  
  /* for sorting dropdown style */
  .d-flex select {
    font-size: 0.9rem;
    padding: 5px;
  }
  
  .d-flex select option {
    font-size: 0.9rem;
  }
  .d-flex p {
    font-size: 0.8rem;
    font-weight: 500;
    padding-top: 6px;
    color: #666666 ;
  }
 
.vertical-divider {
    border-left: 1px solid #ccc; 
    height: 35px;
    margin: 0 15px;
  }
  
  /* Icons container */
  .vertical-icons {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    gap: 10px; 
    cursor: pointer;
  }
  
  .vertical-icons i {
    font-size: 1.5rem; 
    color: #666; 
    transition: color 0.3s ease; 
  }
  
  .vertical-icons i:hover {
    color: var(--primary-color-gentlemenz); 
  }
  

  .filter-header-name{
    background-color: rgb(240, 240, 240);
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: start;
    font-size: 13px;
  }