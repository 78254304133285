.footer_links li {
  list-style: none;
}
.footer_links ul {
  padding: 0;
}
.footer-sitename {
  margin-top: 1.5rem !important;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 20px 0;
}
.footer-sitename p {
  margin-bottom: 0;
}

.footer .list-unstyled {
  margin-top: 16px;
  padding-left: 15px;
}
.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer{
  background-color:  #FAFBFC;
}