/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.product-page {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
}

/* Images Section */
.images-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 0px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: scale(1);
  cursor: zoom-in;
}

.product-image:hover {
  transform: scale(0.95); /* Zoom out */
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Details Section */
.details-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.product-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.product-subtitle {
  font-size: 16px;
  color: #555;
}

.rating {
  font-size: 14px;
  color: #f39c12;
}

.price-section {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
}

.current-price {
  font-weight: bold;
  color: #e74c3c;
}

.original-price {
  text-decoration: line-through;
  color: #aaa;
}

.discount {
  color: #27ae60;
}

.size-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sizes {
  display: flex;
  gap: 10px;
}

.sizes button {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  font-size: 9px;
}

.sizes button:hover {
  background-color: #f7f7f7;
}

.add-to-bag,
.wishlist {
  padding: 15px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.add-to-bag {
  background-color: #e74c3c;
}

.add-to-bag:hover {
  background-color: #c0392b;
}

.wishlist {
  background-color: #555;
}

.wishlist:hover {
  background-color: #333;
}

.delivery-options {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pincode-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 60%;
}

.check-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
}

.check-button:hover {
  background-color: #2980b9;
}

.offers-section ul {
  padding-left: 20px;
}

.product-details {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}

/* Responsive Design for Tablets and Above */
@media (max-width: 768px) {
  .product-page {
    flex-direction: column;
  }

  .image-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .details-section {
    padding-top: 20px;
  }
}

.pincode-container {
  position: relative;
  display: inline-block;
  width: 100%; /* Adjust width as needed */
}

.pincode-input {
  width: 100%;
  padding-right: 50px; /* Adjust to fit button width */
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.check-button {
  position: absolute;
  top: 28%;
  right: 10px; /* Adjust for spacing */
  transform: translateY(-50%);
  background-color: transparent !important;

  color: #e6365b;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.check-button:hover {
  background-color: #e6365b; /* Hover effect */
}

.pincode-container:focus-within .pincode-input {
  border-color: #ff3f6c;
  outline: none;
}
