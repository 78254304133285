.tab-content {
    background-color: transparent;
  }
  
  .nav-tabs {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ebebeb;
  }
  
  .nav-tabs .nav-item .nav-link.active,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-item:hover .nav-link {
    color: #222;
    font-weight: 600;
  }
  
  .nav-link {
    display: block;
    margin-bottom: -0.1rem;
    padding: 0.8rem;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0;
    text-align: center;
    border-radius: 0;
    border: 0;
    transition: border 0.35s, color 0.35s, background-color 0.35s;
  }
  
  .nav-link-with-img {
    position: relative;
    margin-bottom: -4px;
    height: 100%;
    padding: 1.5rem 0 2rem;
  }
  
  .nav-link-with-img figure {
    height: 7.4rem;
    width: 12.1rem;
  }
  
  .nav-link-with-img .img-cat-title {
    position: absolute;
    bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    min-width: 12.1rem;
  }
  
  .nav-link-with-img:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 8px solid #1a4895 !important;
    transform: scaleX(0) translateX(-50%);
    transform-origin: left;
    transition: transform 0.3s;
    
  }
  
  .nav-link-with-img.active:after,
  .nav-link-with-img:hover:after {
    transform: scaleX(1) translateX(-50%);
  }
  
  .nav-item:not(:last-child) {
    /* margin-right: 2.5rem; */
  }
  
  .nav-fill .nav-item {
    flex: 1;
  }
  
  .tab-content {
    position: relative;
  }
  
  .tab-content>.tab-pane {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 0 !important;
    opacity: 0;
    z-index: -1;
    transition: visibility 0.3s, opacity 0.3s;
  }
  
  .tab-content>.tab-pane:not(.active) {
    overflow: hidden;
    visibility: hidden;
  }
  
  .tab-content>.active {
    position: relative;
    height: auto !important;
    opacity: 1;
    z-index: auto;
    padding: 20px !important;
    
  }
  
  .tab-pane {
    padding: 2.3rem 0;
    line-height: 1.72;
    color: #666;
  }
  
  .tab-pane p {
    line-height: 1.72;
  }
  
  .tab-nav-simple .nav-link {
    border-bottom: 3px solid transparent;
    background-color: transparent;
    color: #666;
    font-size: 14px;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  
  .tab-nav-simple .nav-item .nav-link.active,
  .tab-nav-simple .nav-item.show .nav-link,
  .tab-nav-simple .nav-item:hover .nav-link {
    
    border-bottom-color: #1a4895;
    border-bottom: 3px solid #1a4895;
    border-top: none;
    border-left: none;

    border-right: none;

  }
  
  .tab-nav-center .nav {
    justify-content: center;
  }
  
  .tab-nav-right .nav {
    justify-content: flex-end;
  }
  
  .tab-nav-boxed .nav-item {
    margin-right: 0;
  }
  
  .tab-nav-boxed .nav-link {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  
  .tab-nav-round .nav-item:not(:last-child) {
    margin-right: 2px;
  }
  
  .tab-nav-round .nav-link {
    margin-bottom: 0;
    border-radius: 2.3rem;
    background-color: #f2f3f5;
  }
  
  .tab-nav-round .nav-tabs {
    border-bottom: 0;
  }
  
  .tab-boxed .tab-pane {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .tab-outline .tab-content {
    border: 1px solid #ebebeb;
  }
  
  .tab-outline .nav-tabs {
    border-bottom: 0;
  }
  
  .tab-outline .nav-link {
    padding-top: 1.4rem;
    padding-bottom: 1.5rem;
    border: 1px solid transparent;
    border-top-width: 2px;
  }
  
  .tab-outline .nav-item .nav-link.active,
  .tab-outline .nav-item.show .nav-link,
  .tab-outline .nav-item:hover .nav-link {
    border-color: #ebebeb;
    border-top-color: #1a4895;
    
  }
  
  .tab-outline2 .nav {
    position: relative;
    z-index: 1;
  }
  
  .tab-outline2 .tab-content {
    border: 1px solid #ebebeb;
  }
  
  .tab-outline2 .nav-tabs {
    border-bottom: 0;
  }
  
  .tab-outline2 .nav-link {
    padding: 1.5rem 2.4rem;
    border: 1px solid transparent;
  }
  
  .tab-outline2 .nav-item .nav-link.active,
  .tab-outline2 .nav-item.show .nav-link,
  .tab-outline2 .nav-item:hover .nav-link {
    border-color: #ebebeb;
    border-bottom-color: #fff;
  }
  
  .tab-vertical {
    display: flex;
  }
  
  .tab-vertical .nav-tabs {
    flex-flow: column nowrap;
    width: 27.8%;
    border: 0;
    border-right: 2px solid #ebebeb;
  }
  
  .tab-vertical .tab-content {
    flex: 1;
  }
  
  .tab-vertical .nav-item {
    margin-right: 0;
  }
  
  .tab-vertical .nav-link {
    position: relative;
    margin-right: -2px;
    padding-left: 2rem;
    width: calc(100% + 2px);
    border: 0;
    text-align: left;
  }
  
  .tab-vertical .nav-link:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 2px;
    height: calc(100% - 6px);
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    z-index: 1;
  }
  
  .tab-vertical .tab-pane {
    padding: 1.7rem 2rem;
  }
  
  .tab-vertical.tab-simple .nav-link {
    margin-right: -2px;
  }
  
  .tab-vertical.tab-simple .nav-link:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 2px;
    height: calc(100% - 6px);
    top: 50%;
    transform: translateY(-50%) scale(0);
    background-color: transparent;
    transition: transform 0.3s;
    z-index: 1;
  }
  
  .tab-vertical.tab-simple .nav-item .nav-link.active,
  .tab-vertical.tab-simple .nav-item.show .nav-link,
  .tab-vertical.tab-simple .nav-item:hover .nav-link {
    color: #1a4895;
  }
  
  .tab-vertical.tab-simple .nav-item .nav-link.active:after,
  .tab-vertical.tab-simple .nav-item.show .nav-link:after,
  .tab-vertical.tab-simple .nav-item:hover .nav-link:after {
    background-color: #1a4895;
    transform: translateY(-50%) scale(1);
  }
  
  @media (max-width: 767px) {
    .tab-vertical {
      flex-flow: column nowrap;
    }
  
    .tab-vertical .nav-tabs,
    .tab-vertical .tab-content {
      width: 100%;
    }
  
    .tab-vertical .nav-tabs {
      border: 0;
    }
  
    .tab-vertical .tab-content {
      padding-left: 0;
    }
  
    .tab-vertical.tab-simple .nav-link:after {
      height: 2px;
      width: 100%;
      background-color: transparent;
      top: calc(100% - 2px);
      transform: scale(0);
    }
  
    .tab-vertical.tab-simple .nav-item .nav-link.active:after,
    .tab-vertical.tab-simple .nav-item.show .nav-link:after,
    .tab-vertical.tab-simple .nav-item:hover .nav-link:after {
      transform: scale(1);
    }
  }
  
  .tab-inverse .tab-content {
    border-color: #fff;
  }
  
  .tab-inverse .nav-tabs {
    border: 0;
  }
  
  .tab-inverse .nav-link {
    margin: 0;
    width: 100%;
  }
  
  .tab-inverse .nav-item .nav-link.active,
  .tab-inverse .nav-item.show .nav-link,
  .tab-inverse .nav-item:hover .nav-link {
    background-color: #fff;
    border-color: #fff;
  }
  
  .tab-inverse.tab-simple .nav-link {
    padding-top: 1.4rem;
    border-top: 2px solid transparent;
  }
  
  .tab-inverse.tab-simple .nav-item .nav-link.active,
  .tab-inverse.tab-simple .nav-item.show .nav-link,
  .tab-inverse.tab-simple .nav-item:hover .nav-link {
    border-top-color: #1a4895;
  }
  
  .tab-nav-solid .nav-link {
    padding-bottom: 1.6rem;
    border: 0;
  }
  
  .tab-nav-solid .nav-item .nav-link.active,
  .tab-nav-solid .nav-item.show .nav-link,
  .tab-nav-solid .nav-item:hover .nav-link {
    color: #fff;
    background-color: #1a4895;
    border-color: #1a4895;
  }
  
  @media (max-width: 767px) {
    .nav-link {
      font-size: 1.4rem;
    }
  
    .tab-nav-boxed .nav-link {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  
  @media (max-width: 479px) {
    .tab-nav-round .nav-link {
      margin-bottom: 0.5rem;
    }
  }