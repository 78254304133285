.search {
    width: 100%;
    position: relative;
    display: flex;
  }
  
  .searchTerm {
    width: 100%;
    border: 1px solid #000;
    border-right: none;
    padding: 5px;
    height: 45px;
    border-radius: 5px 0 0 5px;
    outline: none;
    background: inherit;

  }
  
  .searchTerm:focus{
    color: #000;
  }
  
  .searchButton {
    width: 40px;
    height: 45px;
    border: 1px solid #000;
    background: inherit;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
    border-left: none !important;
  }
  
