.faq-container{
    width: 70%;
    margin: 100px auto;
    /* min-height: 350px; */
}

.faq-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
}

.faq-header div{
    display: flex;
    align-items: center;
    gap: 10px;
}

.faq-header div p{
    margin-bottom: 0px;
}

.faq-header div button{
    border: 1px solid #ccc;
    background: transparent;
    cursor: pointer;
    color: #526cd0;
    font-size: 14px;
    padding: 8px 10px ;
    border-radius: 5px;
    text-transform: uppercase;
}
.faq-subcontainer{
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
}

.faq-title{
    border-right: 1px solid #ccc ;
}
.faq-title ul{
    list-style: none;
    margin-bottom: 0px;
    padding-left: 0px;
    /* padding-right: 30px; */

}

.faq-title ul li{
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;


}
.faq-title ul li svg {
  /* font-size: 25px; */
  display: flex;
  align-items: center;
min-height: 20px;
min-width: 20px;

}


.faq-title ul li:not(:last-child){
    padding-bottom: 10px;
}
.faq-description{
    padding-left: 20px;
}

.faq-active{
    color: #eac757;
    border-right: 5px solid #eac757;
}
/*  */

.accordion {
   
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
  }

  .accordion-item:not(:last-child){
    margin-bottom: 10px;
  }
  
 
  .accordion-header h3{
    font-size: 18px;
    margin-bottom: 0px;
  }
  .accordion-header span{
    display: flex;
    align-items:center;
    justify-content: center;
    height: 30px;
    width: 30px;
    font-size: 22px;
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 50px;

    /* height: 20px;
    width: 20px; */
  }
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 15px; */
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
  }
  
  .accordion-content {
    overflow: hidden;
    max-height: 0;
    padding: 0 15px;
  }
  
  .accordion-content p {
    margin: 15px 0;
  }
  

  @media(max-width:1000px){
   .faq-title{
    display: none;
   }
   .faq-subcontainer{
    grid-template-columns: 1fr;
   }
   .faq-description{
    padding-left: 0px;
   }

  }

  @media(max-width:768px){
    .faq-header div{
      flex-direction: column;
    }
  }
  @media(max-width:425px){
    .faq-container{
      width: 80%;}
    .faq-header {
      flex-direction: column;
    }
    .faq-header div{
      flex-direction: row;
      margin-top: 10px;;
    }
  }