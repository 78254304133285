.ExtraSection3_section .img-hover-effect {
  width: fit-content;
  margin: auto;
  overflow: hidden;
}

.ExtraSection3_section .img-hover-effect img {
  transition: all 0.3s ease-in-out;
}
.ExtraSection3_section .img-hover-effect:hover img {
  scale: 1.1;
}
.ExtraSection3_section-title h2 {
  font-size: 48px;
  line-height: 52px;
  margin-bottom: 0;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: 700;
  color: #3e4152;
}
.ExtraSection3_section-title p {
  font-size: 16px;
  letter-spacing: 1px;
  padding-top: 10px;
  margin-bottom: 1rem;
}
.ExtraSection3_section-btn {
  display: inline-block;
  background: #ff3f6c;
  color: #fff;
  text-decoration: none;
  padding: 12px 30px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  border: 1px solid #ff3f6c;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.ExtraSection3_section-btn:hover {
  background-color: #fff;
  color: #ff3f6c;
}

.accordion-button {
  color: #000000 !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  line-height: 28px !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button.collapsed {
  background-color: transparent !important;
}

.accordion {
  --bs-accordion-border-color: none !important;
}
.accordion-header-hover .accordion-button:hover {
  color: #ff3f6c !important;
}