.offer_popup {
  position: fixed;
  z-index: 999;
  background: #fff;
  right: 0;
  bottom: 100px;
  display: flex;
  width: 550px;
  right: -506px;
  transition: right 0.5s ease-in-out;
  -webkit-transition: right 0.5s ease-in-out;
  -moz-transition: right 0.5s ease-in-out;
  -ms-transition: right 0.5s ease-in-out;
  -o-transition: right 0.5s ease-in-out;
}

.offer_popup.show {
  right: 0;
}

.offer_popup .offer_btn {
  background-color: #535766;
  cursor: pointer;
}

.offer_popup .offer_btn h3 {
  writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  width: 36px;
  padding: 7px;
  height: 200px;
  vertical-align: middle;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  color: #fcfcfc;
  display: inline-block;
}

.offer_popup .offer_btn .arrow {
  border-left: 12px solid #fff;
  width: 0;
  height: 0;
  margin: 24px 16px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.offer_popup .offer_content {
  background-image: linear-gradient(270deg, #fef9e5, #fde3f3);
}

.offer_popup .offer_content .o_c_top h6 {
  font-weight: 700;
  font-size: 12px;
  margin: 0;
}

.offer_popup .offer_content .o_c_top h2 {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.offer_popup .offer_content .o_c_top .bottom h6 {
  font-weight: 700;
  font-size: 15px;
  margin-top: 8px;
  width: max-content;
}

.offer_popup .offer_content .o_c_top .bottom h6 span {
  margin-left: 12px;
  font-size: 18px;
  font-weight: 700;
  cursor: text;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

.offer_popup .offer_content .o_c_top .bottom p {
  font-size: 12px;
  margin-top: 4px;
}

.offer_popup .offer_content .o_c_top .bottom a {
  width: 172px;
  height: 48px;
  border-radius: 4px;
  background-color: #ff3f6c;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
}

.offer_popup .offer_content .o_c_bottom h6 {
  width: fit-content;
}

.offer_popup .offer_content .o_c_bottom .o_c_b_p {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  border-top: 1px solid #d3d3d3;
  padding: 10px;
  align-items: center;
}

.offer_popup .offer_content .o_c_bottom .o_c_b_p h6 {
  font-size: 13px;
  font-weight: 700;
  margin-left: 12px;
  margin-bottom: 0;
}

.offer_popup .offer_content .o_c_bottom .o_c_b_p h6 i {
  padding-right: 8px;
  color: var(--green);
  font-size: 16px;
}

.offer_content {
  width: 100%;
}
@media (min-width: 768px) {
  .offer_popup {
    width: 550px;
  }

  .offer_popup .offer_btn h3 {
    font-size: 24px;
    height: 200px;
  }

  .offer_popup .offer_content {
    padding: 2rem;
  }

  .offer_popup .offer_content .o_c_top .bottom a {
    max-width: 172px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }
}

