.coupon-btn{
    background-color: #ff3f6c;
    border-radius: 2px;
    border-width: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 10px 16px;
    text-align: center;
    margin-top: 20px;
}