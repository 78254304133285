table {
  width: 100%;
  border-collapse: collapse;
}

th {
  color: #999;
}
.cart-container {
  height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.cart-container th,
.cart-container td {
  padding: 10px;
}

tr {
  border-bottom: 1px solid #ccc !important;
}

.cart-pro-img {
  display: flex;
  gap: 20px;
  width: 400px;
}

.cart-pro-img img {
  width: 50px;
}

.cart-pro-img p {
  line-height: 1;
  font-size: 14px;
}

.cart-quantity button {
  border: none;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4eaec;
}

.cart-quantity div {
  /* height: 100%; */
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-delete div {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* mobile card */
.cart-desktop {
  display: block;
}

.cart-mobile {
  display: none;
}

.cart-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  gap: 20px;
  /* flex-direction: column; */
  background-color: #f9f9f9;
  position: relative;
}
.cart-card-img {
  width: 100px;
}

.cart-card-img img {
  width: 100%;
}
.cart-card-details h3 {
  font-size: 16px;
}

.mobile-cart-price {
  display: flex;
  gap: 10px;
  font-size: 14px;
}

.mobile-cart-close {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;

  justify-content: center;
  cursor: pointer;
  border: 1px solid #999;
  border-radius: 20px;
  background-color: var(--primary-color-gentlemenz);
}
.mobile-cart-close svg {
  font-size: 18px;
  color: #fff;
}

@media (max-width: 768px) {
  .cart-desktop {
    display: none;
  }

  .cart-mobile {
    display: block;
  }
}

@media (max-width: 375px) {
  .mobile-cart-close {
    height: 18px;
    width: 18px;
  }
}

@media (max-width: 320px) {
  .mobile-cart-close {
    top: 5px;
    right: 5px;
    height: 18px;
    width: 18px;
  }
}
