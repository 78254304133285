.section12-container {
    width: 100%;
    height: auto; 
    min-height: 545px; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #fff;
  }
  
  .section12-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }
  
  .section12-first-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .section12-first-content h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .section12-first-content p {
    font-size: 1.2rem;
  }
  
  .section12-countdown {
    display: flex;
    flex-wrap: nowrap; 
    justify-content: center;
    gap: 15px;
  }
  
  .section12-time-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #fff;
    width: 100px; 
  }
  
  .section12-time-box span:first-child {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .section12-time-box span:last-child {
    font-size: 1rem;
    margin-top: 5px;
  }
  
  @media (max-width: 768px) {
    .section12-countdown {
        flex-wrap: wrap;
    }
    .section12-first-content h1 {
      font-size: 2rem;
    }
    .section12-first-content h2 {
      font-size: 1.5rem;
    }
    .section12-first-content p {
      font-size: 1rem;
    }
    .section12-time-box {
      width: 80px; 
      padding: 10px;
    }
  }
  