.login-container{
    background-color:#feeef2 ;
    /* min-height: 90vh; */
    display: flex;
    justify-content: center;
    
}
.login-subcontainer {
    background-color: #fff;
}

.login-banner-img-container img {
    width: 100%;
}

.login-input-container-field{
    padding: 30px;
}
.login-btn-toggle{
    display: flex;
    gap: 10px;
}
.login-btn-toggle h2{
    font-size: 20px;
    cursor: pointer;
}

.login-input-filed-container input {
    display: block;
    width: 100%;

}

.form-title {
    font-size: 26px;
}

.login-forget {
    text-align: right;
    color: #FF3F6C;

    /* text-decoration: underline; */
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}
.login-no-account p{
    margin-top: 20px;
    font-size: 14px;
}

.login-no-account p span{
    color: #FF3F6C;
    cursor: pointer;

}

.register-form-container form .input{
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #d4d5d9;

}


.reg-checkbox{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}
.reg-checkbox p{
    margin: 0;
    font-size: 14px;
}

.already-have-acc {
    font-size: 14px ;
    margin: 10px 0;
}
.already-have-acc span{
    color: #FF3F6C;
    cursor: pointer;

}
.reg-checkbox p span{
    color: #FF3F6C;
    cursor: pointer;
}
.register-form-container form .input:not(:last-child){
    margin-bottom: 10px;
}

.css-3vnhty {
    border-radius: 0px !important;
}


/* forget */
.forget-input-container{
    margin-top: 20px;
}

.error {
    color: #FF3F6C;
    font-size: 14px;

}

.otp-container{
    position: relative;

}

.otp-close-btn{
    position: absolute;
    right: -40px;
    top: -45px;
    border: none;
    border: 50%;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-weight: 600;
    /* font-size: 10px; */
}

/* login-in-from */
.login-pw-container{
    position: relative;
}

.login-pw-container svg{
    position: absolute;
    font-size: 22px;
    top: 23%;
    right: 10px;
    cursor: pointer;

}

/* @media(max-width:768px){
    .login-container{

    }
} */

@media(max-width:768px){
    .login-container{
        min-height: 200px;
    }

}