.section9-contents {
  display: flex;
  align-items: center;
  border-right: 1px dashed gray;
}
.section9-contents img {
  width: 30%;
  height: auto;
}

@media (max-width: 575px) {
  .section9-contents {
    border-right: none;
  }
}
