.product-wrap.dy_product-w-p {
    margin-bottom: 2rem;
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
  .dy_product-w-p .product {
    position: relative;
    overflow: hidden;
    font-size: 1.4rem;
    color: #999;
    transition: 0.3s;
    flex-direction: column;
  }
  .dy_product-w-p .product-media {
    position: relative;
    margin-bottom: 0;
    margin: 0;
    transition: box-shadow 0.3s;
  }
  .dy_product-w-p .product-media img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out !important;
  }
  .dy_product-w-p .product:hover .product-media img {
    transform: scale(1.05);
  }
  .dy_product-w-p .product-media img:last-child {
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  
  .dy_product-w-p .product-media img:first-child {
    position: relative;
    opacity: 1;
  }
  .dy_product-w-p .product-action-vertical {
    position: absolute;
    top: 15px;
    right: 15px;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
  }
  .dy_product-w-p .product-action {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
    z-index: 10;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
  }
  .dy_product-w-p .btn-product-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    margin-bottom: 0.5rem;
    border: 1px solid #e1e1e1;
    border-radius: 50%;
    background-color: #fff;
    color: #999;
    font-size: 1.6rem;
    font-weight: 700;
    transition: border-color 0.3s, color 0.3s, background-color 0.3s;
  }
  .btn-product-icon:hover {
    border-color: var(--primary-color-gentlemenz);
    color: #fff;
    background-color: var(--primary-color-gentlemenz);
  }
  .dy_product-w-p .btn-quickview {
    opacity: 0.8;
    transition: opacity 0.3s;
  }
  .dy_product-w-p .btn-product {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 1.14em 0;
    background-color: var(--primary-color-gentlemenz);
    color: #fff;
    font-size: 0.85rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    transition: opacity 0.3s;
    text-decoration: none;
  }
  .dy_product-w-p .btn-wishlist {
    line-height: 1;
  }
  .dy_product-w-p .btn-product-icon:not(.btn-wishlist) i {
    margin-bottom: 2px;
  }
  .dy_product-w-p .btn-product-icon i {
    display: inline-block;
    line-height: 1;
  }
  .dy_product-w-p .product-details {
    position: relative;
    background-color: #fff;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
  }
  .dy_product-w-p .product-cat {
    margin-bottom: 0.2rem;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .dy_product-w-p .product-cat a {
    color: var(--text-grey) !important;
    text-decoration: none;
  }
  .dy_product-w-p .product-name {
    margin-bottom: 0.3rem;
    font-family: inherit;
    font-size: 0.85rem;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #666666 !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  
    max-width: 100%;
    display: block;
    padding-top: 5px;
  }
  @media (max-width: 768px) {
    .dy_product-w-p .product-name {
      font-size: 0.9rem;
      max-width: 200px;
    }
  }
  
  @media (max-width: 576px) {
    .dy_product-w-p .product-name {
      font-size: 0.8rem;
      max-width: 150px;
    }
  }
  .dy_product-w-p .product-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.1;
    color: #222;
    
  }
  .dy_product-w-p .product-label-group {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 2rem;
    top: 2rem;
    max-width: 10rem;
  }
  .dy_product-w-p .product-label.label-new {
    background: #26c;
  }
  .dy_product-w-p .product-label {
    display: inline-block;
    text-align: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1.1rem;
    font-size: 1.1rem;
    border-radius: 2px;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
  }
  .dy_product-w-p .ratings-container {
    display: flex;
    align-items: center;
    margin-left: 1px;
    line-height: 1.1;
    margin-bottom: 1.2rem;
    font-size: 11px;
    letter-spacing: 0.2em;
  }
  .dy_product-w-p .product-details > :last-child {
    margin-bottom: 0;
  }
  .dy_product-w-p .product-price .new-price {
    margin-right: 1rem;
    text-decoration: none;
  }
  .dy_product-w-p .product-price .old-price {
    color: #aaa;
  }
  .dy_product-w-p .ratings-full {
    cursor: pointer;
  }
  .dy_product-w-p .ratings,
  .ratings-full {
    position: relative;
  }
  .dy_product-w-p .rating-reviews {
    margin: 2px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1rem;
    color: #aaa;
    letter-spacing: 0;
    text-transform: capitalize;
  }
  .dy_product-w-p .ratings-full + .rating-reviews {
    margin-left: 1rem;
  }
  .dy_product-w-p .product:hover .product-action,
  .dy_product-w-p .product:hover .product-action-vertical {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }
  .action-btn{
    background-color: #ff3f6c !important;
    border-radius: 0px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
  }