.card {
  overflow: hidden;
  /* max-width: 300px; */
  font-family: Arial, sans-serif;
  background: #fff;
  border-radius: 0px !important;
  border: "none" !important;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.card-hover:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card-image {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.card-image img {
  width: 100%;
  height: 90%;
}

.ad-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}

.card-details {
  padding: 10px;
}

.card-brand {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-name {
  font-size: 12px;
  color: #555;
  margin-bottom: 10px;
}

.card-pricing {
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin-bottom: 10px;
}

.card-discount-price {
  font-size: 13.5px;
  font-weight: bold;
  color: #000;
}

.card-original-price {
  font-size: 12px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #999;
}

.card-discount-percentage {
  font-size: 10px;
  color: #f00;
}

.image-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

/* Quick View Button */
.quick-view {
  position: absolute;
  bottom: -40px; /* Hidden initially */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0;
  transition: bottom 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
}

.card:hover .quick-view {
  bottom: 0; /* Slide up */
  opacity: 1;
}
.progress-bar {
  background-color: #ff3f6c !important;
}
.avialable-progress-value {
  font-size: 0.8rem;
  padding-top: 10px;
}
