.range-input {
    position: relative;
  }
  
  .rangeslider .progress {
    --bs-progress-height: 0.3rem;
    --bs-progress-bg: #ff3f6c;
  }
  .range-input input {
    position: absolute;
    width: 100%;
    height: auto;
    top: -13px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .range-input input[type="range"]::-webkit-slider-thumb {
    width: 20px;
    -webkit-appearance: none;
    height: 20px;
    background: #fffaf3 !important;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border: 2px solid #ff3f6c !important;
    
  }
  /* .clearFilterButton {
    border: none;
    padding: 0.5em 1em;
    border-radius: 2px;
    font-weight: 700;
    background-color: #222;
    color: #fff;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }
  .clearFilterButton:hover,
  .clearFilterButton:focus,
  .clearFilterButton:active {
    background-color: var(--primary-color-gentlemenz);
  } */
  input[type="range"]::-webkit-slider-thumb {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    /* background: var(--primary-color-gentlemenz); */
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    border-radius: 50% !important;
  }
  
  input[type="range"]::-moz-range-thumb {
    height: 17px;
    width: 17px;
    border: none;
    border-radius: 50%;
    /* background: var(--primary-color-gentlemenz); */
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    border-radius: 50% !important;
  }
  .price-input {
    margin-top: 20px;
    /* color: var(--primary-light-color-gentlemenz); */
    color: #000;
    font-weight: 400 !important;
  }
  