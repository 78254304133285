
.page-not__found{
    background-color:#feeef2 ;
    min-height: 90vh;
    display: flex;
    align-items: center;
    padding: 20px 0 ;
}


.page-not-found_container{
    width: 60%;
    margin: 0px auto;
    padding: 0  40px ;
    display: grid;
    grid-template-columns: 0.75fr 0.5fr;
}


.page-not-found-content{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.page_content-code,.page_conten-url{
    font-size: 18px;
}
.page_content-code span,.page_conten-url span{
    color: #999
}
.page-not-found-logo img {
    height: 150px;
}

.page_conten-url{
    width: 80%;
}

.page-not-found-image{
    /* margin-left: -100px; */
}


.page-not-found-btn{
    background-color: #FF3F6C;
    color: #fff;
    align-self: flex-start;
    font-weight: 600;
    border: none;
    padding: 10px 20px;
    cursor: pointer;

    
}




@media (max-width:800px) {
    
    .page-not-found_container{
        width: 90%;
        grid-template-columns: 1fr;
        padding: 0  20px ;
    margin: 0px auto;
        
    }

    .page-not-found-image{
        margin-left: 0px;
      text-align: center;
      grid-row-start: 1;
       

        
    }
    .page_conten-url{
        width: 100%;
    }

   
    
}