.pgntn_par {
    padding: 20px;
    align-items: center;
    border-top: 1px solid lightgray;
    margin: 0;
  }
  
  .pgntn_par .page_count {
    color: #535766;
    font-size: 14px;
  }
  
  .pgntn_par .page_number {
    display: flex;
    gap: 40px;
    align-items: center;
  }
  
  .pgntn_par .page_number ul {
    display: flex;
    list-style: none;
    margin: 0;
    gap: 10px;
    align-items: center;
    padding: 0;
  }
  
  .pgntn_par .page_number ul li a {
    display: inline-block;
    font-weight: 700;
    padding: 4px 8px;
    border-radius: 2px;
    color: var(--dark_grey);
    text-decoration: none;
  }
  
  .pgntn_par .page_number ul li.active a {
    background-color: var(--dark_grey);
    color: #fff;
  }
  
  .pgntn_par .page_number > a {
    height: 42px;
    border-radius: 4px;
    border: 1px solid #d4d5d9;
    text-align: center;
    padding: 10px 22px;
    font-weight: 700;
    text-decoration: none;
    color: var(--dark_grey);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pgntn_par .page_number > a:hover {
    border: 1px solid #000000;
  }
  
  .pgntn_par .page_number > .prev i {
    padding-right: 6px;
    font-size: 14px;
  }
  
  .pgntn_par .page_number > .next i {
    padding-left: 6px;
    font-size: 14px;
  }
  

  
  @media (width <=499.98px) {
  
  
    .pgntn_par {
      padding: 20px 0;
    }
  
    .pgntn_par > .col-md-12 {
      padding: 0;
    }
  }
  
  @media (width <=474.98px) {
    .pgntn_par .page_number {
      gap: 20px;
    }
  
    .pgntn_par .page_number {
    }
  }
  
  @media (width <=374.98px) {
    .pgntn_par .page_number {
      flex-wrap: wrap;
      text-align: center;
    }
  
    .pgntn_par .page_count {
      order: 3;
    }
  }
  .pre {
    color: #666666 !important;
  }
  .next {
    color: #666666 !important;
  }
  .page-1 {
    color: #5c4033 !important;
  }