.coupon-text {
  color: #535766;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.apply-coupon-btn {
  border: 1px solid #ff3f6c;
  background-color: inherit;
  color: #ff3f6c;
  text-decoration: underline;
  padding: 5px 15px;
  font-size: 14px;
}
.price-details-content {
  border-bottom: 1px solid #eaeaec;

  margin-bottom: 15px;
  padding-bottom: 15px;
}
.price-details-content > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.price-details-content > div .text-green {
  color: #14958f;
}

.total-amount {
  align-items: center;
  color: #3e4152;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 16px;
}
.continue-btn {
  border-radius: 0 !important;
  background-color: #ff3f6c !important;
  border: none !important;
  font-size: 14px !important;
  margin-top: 20px;
}

.gift_personalisation_cont {
  background: #fff1ec;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 12px 12px 63px;
  position: relative;
}

.gift_personalisation_cont > img {
  left: 10px;
  position: absolute;
  top: -1px;
}
.gift_personalisation_cont a {
  background-color: initial;
  color: #ff3f6c !important;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  /* margin-top: 5px; */
}
.gif-heading{
    font-size: 12px;
    font-weight: 800;
}