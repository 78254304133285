.resend-otp-container{
    text-align: right;
    color: #FF3F6C;


}

.resend-otp-container span{
    cursor: pointer;
    font-size: 14px;
    color: #FF3F6C;

}


.otp-btn{
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #FF3F6C;
    color: #fff;
    padding: "10px 20px";
   border:none;
   cursor: pointer;
}