.contact-heading {
  font-size: 14px;
  font-weight: 600;
  color: black;
  border-bottom: 1px solid green !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
  max-width: 300px;
  margin: 0px auto;
  text-align: center;
}
.btn-primary {
    background-color: #ff3f6c !important;
    border-color: #ff3f6c !important;
    color: #fff;
    font-size: 14px !important;
    font-weight: 600 !important;
}
.form-control::placeholder {
    font-size: 13px;
  }
  .custom-select__placeholder {
    font-size: 13px;  /* Adjust this value as needed */
  }