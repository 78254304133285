.contact-us-container {
    background-color: #feeef2;
    padding-top: 20px;
    display: flex;
flex-direction: column;
gap: 20px;    
}

.contact-sub-container {
    width: 40%;
    margin:  0 auto;
    background-color: #fff;
    padding: 20px ;
    /* margin-top: 80px; */

}
.contact-sub-container form input,.contact-sub-container form textarea{
    display: block;
    width: 100%;
    border: none;
 
    border-bottom: 2px solid #d3d3d3;
    outline: none;
    padding: 6px 2px;
    
}

.contact-sub-container form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.contact-us-btn {
    align-self: flex-start;
    border: 1px solid #ff3f6c;

    padding: 10px 60px;
    background-color: #ff3f6c;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
transition: all 0.2s ease-in-out;

}

.contact-us-btn:hover {
    border: 1px solid #ff3f6c;
    background-color: #fff;
    color: #ff3f6c;
}

.error-text{
    color: #ff3f6c;

}
@media(max-width:1794px){
    .contact-sub-container{
        margin-top: 80px;
    }
}

@media(max-width:768px){
    .contact-sub-container{
        width: 90%;
        margin-top: 80px;
    }
}