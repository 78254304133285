.cart-product-name {
  font-size: 14px;
}
@media (max-width: 768px) {
  .cancel-icon {
    display: none;
  }
}

.cancel-icon-img {
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 1;
  color: black;
}
