.custom-select {
    border: none !important; /* Removes the default border */
    outline: none;           /* Removes outline when focused */
    background-color: transparent;  /* Ensures no background color */
           /* Removes native dropdown arrow */
    padding: 0.5rem;         /* Add padding for better look */
    font-size: 1rem;         /* Adjust font size */
    width: 145px;             /* Adjust width or set to 100% if you want it full-width */
  }
  

  
  /* Optional: Add custom styles on hover */
  .custom-select:hover {
    background-color: inherit;  
    cursor: pointer;            
  }
  
  /* Optional: Add focus styles for better UX */
  .custom-select:focus {
    border: none;        /* Keep border removal when focused */
    outline: none;       /* No outline on focus */
    background-color: inherit; /* Change background when focused */
  }